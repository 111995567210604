import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const DirectDeposit: React.FC<SvgIconProps> = ({ titleAccess, ...props }) => {
  return (
    <SvgIcon {...props} aria-label={titleAccess} titleAccess={titleAccess}>
      <path d="M23 22V24H1V22H23ZM17 0V2H5V21H3V0H17ZM20 3V21H18V5H8V21H6V3H20ZM13 19C13.5523 19 14 19.4477 14 20C14 20.5523 13.5523 21 13 21C12.4477 21 12 20.5523 12 20C12 19.4477 12.4477 19 13 19ZM13 10C15.2091 10 17 11.7909 17 14C17 16.2091 15.2091 18 13 18C10.7909 18 9 16.2091 9 14C9 11.7909 10.7909 10 13 10ZM13 12C11.8954 12 11 12.8954 11 14C11 15.1046 11.8954 16 13 16C14.1046 16 15 15.1046 15 14C15 12.8954 14.1046 12 13 12ZM13 7C13.5523 7 14 7.44772 14 8C14 8.55229 13.5523 9 13 9C12.4477 9 12 8.55229 12 8C12 7.44772 12.4477 7 13 7Z" />
    </SvgIcon>
  );
};

export default DirectDeposit;
