import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { TextInput } from "components/textInput";
import { FormikProps } from "formik";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const useValidation = () => {
  const [t] = useTranslation();
  return useMemo(() => {
    return Yup.object().shape({
      token: Yup.string().required(t("accountLookup.token.required")),
    });
  }, [t]);
};

enum FormFields {
  Token = "token",
}

export type Form = {
  token: string;
};

export const initialValues: Form = { token: "" };

type Props = FormikProps<Form>;

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    width: "100%",
    margin: 0,
  },
  submit: {
    width: "100%",
    height: "3.75rem",
  },
}));

const AccountLookupForm: React.FC<Props> = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const {
    values: { token },
    errors,
    touched,
    isValid,
    handleChange,
    handleSubmit,
    setFieldTouched,
  } = props;

  const change = (name: FormFields, e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} className={classes.formContainer}>
        <Grid item xs={12}>
          <Typography variant="body1">{t("accountLookup.instructions")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id={FormFields.Token}
            name={FormFields.Token}
            label={t("accountLookup.token.label")}
            helperText={touched.token ? errors.token : ""}
            error={touched.token && Boolean(errors.token)}
            value={token}
            onChange={change.bind(null, FormFields.Token)}
            inputProps={{
              autoCorrect: "off",
              autoCapitalize: "none",
              autoComplete: "off",
            }}
            required
          ></TextInput>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit" className={classes.submit} disabled={!isValid}>
            {t("submit")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AccountLookupForm;
