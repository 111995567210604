import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconBubble from "components/iconBubble";
import Link from "components/link";
import { useContact } from "data/contact";
import CheckIcon from "icons/Checkmark";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    width: "100%",
    margin: 0,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    margin: "1.5rem 0",
  },
  icon: {
    width: "4rem",
    height: "4rem",
    fontSize: "2.35rem",
    "& > .MuiSvgIcon-root": {
      width: "2rem",
      height: "2rem",
    },
  },
  centered: {
    textAlign: "center",
  },
  phoneNumber: {
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
}));

const AddressChangeSuccess: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const getContact = useContact();
  const contact = getContact();
  return (
    <Grid container spacing={2} className={classes.formContainer}>
      <Grid item xs={12} className={classes.iconContainer}>
        <IconBubble color={"primary"} className={classes.icon}>
          <CheckIcon titleAccess={t("aria.checkmarkIcon")}></CheckIcon>
        </IconBubble>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.centered}>
          {t("addressChangeSuccess.title")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.centered}>
          <Trans i18nKey="addressChangeSuccess.message" values={contact}>
            Please allow 7-10 days for processing and mailing time. You may call{" "}
            <Link
              className={classes.phoneNumber}
              href={`tel:${contact.phone}`}
              color="secondary"
              variant="inherit"
              data-testid="contact-phonenumber"
            >
              1 (888) 687-4722
            </Link>
          </Trans>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AddressChangeSuccess;
