import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const EmeraldCard: React.FC<SvgIconProps> = ({ titleAccess, ...props }) => {
  return (
    <SvgIcon {...props} aria-label={titleAccess} titleAccess={titleAccess}>
      <path d="M20,2 C22.209,2 24,3.791 24,6 L24,6 L24,18 C24,20.209 22.209,22 20,22 L20,22 L4,22 C1.791,22 0,20.209 0,18 L0,18 L0,6 C0,3.791 1.791,2 4,2 L4,2 Z M20,4 L4,4 C3.993,4 3.987,4.002 3.98,4.002 L3.98,4.002 L19.961,20 L20,20 C21.103,20 22,19.103 22,18 L22,18 L22,6 C22,4.897 21.103,4 20,4 L20,4 Z M2,14.926 L2,18 C2,19.103 2.897,20 4,20 L4,20 L7.244,20 L2,14.926 Z M12.649,15.51 L11.283,20 L17.135,20 L12.649,15.51 Z M11.019,14 L3.919,14 L9.403,19.306 L11.019,14 Z M2.233,5.083 C2.09,5.358 2,5.667 2,6 L2,6 L2,12 L9.144,12 Z M19,7 L19,9 L15,9 L15,7 L19,7 Z"></path>
    </SvgIcon>
  );
};

export default EmeraldCard;
