import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const Checkmark: React.FC<SvgIconProps> = ({ titleAccess, ...props }) => {
  return (
    <SvgIcon {...props} aria-label={titleAccess} titleAccess={titleAccess}>
      <path d="M20.1534 4L23 6.76749L8.47419 20.8898L1 13.6232L3.84656 10.8558L8.47419 15.3548L20.1534 4Z" />
    </SvgIcon>
  );
};

export default Checkmark;
