import React from "react";
import { useTranslation } from "react-i18next";
import WarningIcon from "icons/Warning";
import IconBubble, { IconBubbleProps } from "./iconBubble";

type Props = Partial<IconBubbleProps>;

const Warning: React.FC<Props> = ({ color = "yellow" }) => {
  const [t] = useTranslation();

  return (
    <IconBubble color={color}>
      <WarningIcon titleAccess={t("aria.warningIcon")}></WarningIcon>
    </IconBubble>
  );
};

export default Warning;
