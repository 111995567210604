import { PropTypes } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import classnames from "classnames";
import React from "react";
import { yellow } from "styles/colors";

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      width: "2rem",
      height: "2rem",
      padding: "0.5rem",
      borderRadius: "50%",

      "& > svg": {
        width: "1rem",
        height: "1rem",
      },

      "&.default": {
        color: theme.palette.primary.main,
      },
      "&.primary": {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
      "&.secondary": {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.secondary.main,
      },
      "&.error": {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.common.white,
      },
      "&.yellow": {
        backgroundColor: yellow,
        color: theme.palette.common.black,
      },
    },
  };
});

type Props = {
  className?: string;
  color: PropTypes.Color | "error" | "yellow";
};

export type IconBubbleProps = Props;

const IconBubble: React.FC<Props> = ({ color, className, children }) => {
  const classes = useStyles();

  return <div className={classnames(classes.root, color, className)}>{children}</div>;
};

export default IconBubble;
