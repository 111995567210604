import React from "react";
import { useTranslation, Trans } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useContact } from "data/contact";
import DocumentTitle from "components/documentTitle";
import Header from "components/header";
import Link from "components/link";
import Phone from "icons/Phone";
import { useStyles } from "./styles";
import Wrapper from "components/wrapper";

const NotFound: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const getContact = useContact();
  const contact = getContact();
  return (
    <Wrapper in={true} timeout={750}>
      <DocumentTitle title={t("app.errors.notFound.title")}>
        <Header title={t("app.errors.notFound.header")} />
        <Grid container component="main">
          <Grid item xs={12} className={classes.message}>
            <Typography variant="h2" data-testid="error-message">
              {t("app.errors.notFound.message")}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.contact}>
            <Phone titleAccess={t("aria.phoneIcon")} />
            <Typography variant="body1" data-testid="contact-message">
              <Trans i18nKey="app.errors.notFound.contact" values={contact}>
                Please try again at a later time or contact us at{" "}
                <Link
                  className={classes.phoneNumber}
                  href={`tel:${contact.phone}`}
                  color="secondary"
                  variant="inherit"
                  data-testid="contact-phonenumber"
                >
                  1-800-472-5625
                </Link>
              </Trans>
            </Typography>
          </Grid>
        </Grid>
      </DocumentTitle>
    </Wrapper>
  );
};

export default NotFound;
