import React from "react";
import Fade, { FadeProps } from "@material-ui/core/Fade";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Footer from "components/footer";

type Props = FadeProps & {
  loading?: React.ReactNode;
  children?: React.ReactNode;
};

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    padding: "0",
  },
  containerBody: {
    flex: "1 1 auto",
    display: "flex",
    "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)": {
      minHeight: "100vh",
    },
  },
  bodyBackground: {
    width: "100%", //Required for IE11
    paddingBottom: "2rem",
    backgroundColor: theme.palette.common.white,
  },
  containerFooter: {
    flex: "0 0 auto",
  },
}));

const Wrapper: React.FC<Props> = ({ children, loading, ...fadeProps }) => {
  const classes = useStyles();

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Grid item xs={12} className={classes.containerBody}>
        {loading}
        <Fade {...fadeProps}>
          <div className={classes.bodyBackground}>{children}</div>
        </Fade>
      </Grid>
      <Grid item xs={12} className={classes.containerFooter}>
        <Fade {...fadeProps}>
          <Footer />
        </Fade>
      </Grid>
    </Container>
  );
};

export default Wrapper;
