import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { formatCurrency } from "../formatters";
import Arrow from "./arrow";
import Link from "./link";

export const useStyles = makeStyles((theme: Theme) => ({
  ledger: {
    "& .MuiTableRow-root": {
      "& .name": {
        padding: "1rem 0rem",
      },
      "& .amount": {
        padding: "1rem 0rem",
      },
    },
  },
  ledgerItem: {
    "& .MuiTableCell-root": {
      "font-weight": 500,
      color: theme.palette.grey[900],
    },

    "&.total": {
      backgroundColor: theme.palette.grey[100],
      "& .MuiTableCell-root": {
        paddingTop: "0.5rem",
        paddingBottom: "1rem",
        border: "none",
        fontSize: "1rem",
      },
    },
    "&.disbursement": {
      backgroundColor: theme.palette.grey[100],
      "& .MuiTableCell-root": {
        paddingTop: "0.5rem",
        paddingRight: "0.5rem",
        paddingLeft: "0.5rem",
        paddingBottom: "1rem",

        "& .MuiTypography-root": {
          color: theme.palette.grey[900],
        },
      },
    },
  },
  subledgerItem: {
    "& .MuiTableCell-root": {
      color: theme.palette.grey[600],
    },
  },
  arrow: {
    verticalAlign: "middle",
  },
}));

type Props = {
  credits: LedgerLineItem;
  debits: LedgerLineItem[];
  disbursementFlag?: React.ReactNode;
  disbursementDetail?: React.ReactNode;
};

export type LedgerLineItem = {
  name: string;
  amount: number;
};

const Ledger: React.FC<Props> = ({ credits, debits, disbursementFlag, disbursementDetail }) => {
  const classes = useStyles();
  const [showDebits, setShowDebits] = useState(false);
  const totalDebits = useMemo(() => debits.reduce((sum, { amount }) => sum + amount, 0), [debits]);
  const total = useMemo(() => credits.amount - totalDebits, [credits, totalDebits]);
  const [t] = useTranslation();

  const toggleShowDebits = useCallback(() => {
    setShowDebits(!showDebits);
  }, [showDebits]);

  return (
    <Table className={classes.ledger}>
      <TableBody>
        <TableRow className={classes.ledgerItem} data-testid={"credit-0"}>
          <TableCell className="name" colSpan={2}>
            {credits.name}
          </TableCell>
          <TableCell className="amount" align="right">
            {formatCurrency(credits.amount)}
          </TableCell>
        </TableRow>
        <TableRow className={classes.ledgerItem} data-testid={`fee-summary`}>
          <TableCell className="name" colSpan={2}>
            <Link
              data-testid={`fee-toggle`}
              component="button"
              variant="body2"
              color="secondary"
              aria-expanded={showDebits}
              aria-controls={`fee-details`}
              onClick={toggleShowDebits}
              endIcon={<Arrow className={classes.arrow} fontSize="small" expand={showDebits} />}
            >
              {t("fees")}
            </Link>
          </TableCell>

          <TableCell className="amount" align="right">
            {formatCurrency(-1 * totalDebits)}
          </TableCell>
        </TableRow>
        {showDebits &&
          debits.map((debit, index) => (
            <TableRow className={classes.subledgerItem} key={`debit-${index}`} data-testid={`debit-${index}`}>
              {index === 0 && <TableCell rowSpan={debits.length}></TableCell>}
              <TableCell className="name">{debit.name}</TableCell>
              <TableCell className="amount" align="right">
                {formatCurrency(-1 * debit.amount)}
              </TableCell>
            </TableRow>
          ))}
        <TableRow className={classnames(classes.ledgerItem, "total")} data-testid={`total`}>
          <TableCell className="name" colSpan={2}>
            {disbursementFlag}
          </TableCell>
          <TableCell className="amount" align="right">
            {formatCurrency(total)}
          </TableCell>
        </TableRow>
        {disbursementDetail && (
          <TableRow className={classnames(classes.ledgerItem, "disbursement")} data-testid={`disbursement`}>
            <TableCell className="name" colSpan={3}>
              {disbursementDetail}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default Ledger;
