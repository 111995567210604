import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import { startOfToday } from "date-fns";
import isToday from "date-fns/isToday";
import ChevronDown from "icons/ChevronDown";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

type Props = {
  className?: string;
  lastUpdate: Date;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
  },
  content: {
    "&:last-child": {
      paddingBottom: "1rem",
    },
    "& .MuiTypography-h2": {
      marginBottom: "0.75rem",
    },

    "& .MuiTypography-body2": {
      color: theme.palette.grey[600],
      whiteSpace: "pre-line",
    },
  },
  centered: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const LastUpdateBanner: React.FC<Props> = ({ className, lastUpdate }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const today = startOfToday();
  const isUpdatedToday = isToday(lastUpdate);
  const title = isUpdatedToday ? t("newUpdatesToday") : t("lastUpdateCheck", { today });
  const message = isUpdatedToday ? (
    <Trans i18nKey="seeBelow">
      See below <ChevronDown />
    </Trans>
  ) : (
    t("lastUpdateMessage")
  );

  return (
    <Card className={classnames(classes.root, className)}>
      <CardContent className={classes.content}>
        <Typography data-testid="last-update-banner-title" className={classes.centered} variant="h2">
          {title}
        </Typography>
        <Typography data-testid="last-update-banner-message" className={classes.centered} variant="body2">
          {message}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default LastUpdateBanner;
