import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Checkmark from "./checkmark";
import Hourglass from "./hourglass";
import Arrow from "./arrow";
import Warning from "./warning";
import Link from "./link";
import BankCheck from "./bankCheck";
import { medium } from "styles/fonts";

export type StepItem = {
  title: string;
  message: string;
  iconType: "checkmark" | "hourglass" | "presentHourglass" | "bankCheck" | "exclamation";
  dispersementChart?: React.ReactNode;
  moreDetailsText?: React.ReactNode;
  date: string;
};

type Props = {
  list: StepItem[];
  showConnector?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    "& .MuiTypography-caption": {
      paddingLeft: "1rem",
      textTransform: "uppercase",
      flexShrink: 0,
      fontWeight: medium,
    },
  },
  border: {
    "& .MuiCollapse-container": {
      paddingBottom: theme.spacing(1) / 2,
      borderBottom: `0.0625rem solid ${theme.palette.divider}`,
    },
  },
  moreDetails: {
    textAlign: "left",
    margin: "0.5rem 0",
    "& span": {
      display: "inline-block",
      minWidth: "5.05rem",
    },
    "& .MuiSvgIcon-root": {
      verticalAlign: "middle",
    },
  },
  noconnector: {
    borderLeft: "none",

    "& .MuiStepConnector-lineVertical": {
      borderLeft: "none",
    },
  },
}));

const StepList: React.FC<Props> = ({ list, showConnector }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [activeStep, setActiveStep] = useState<number[]>([]);

  const iconForType = (type: "checkmark" | "hourglass" | "presentHourglass" | "bankCheck" | "exclamation") => {
    switch (type) {
      case "checkmark":
        return Checkmark;
      case "hourglass":
      case "presentHourglass":
        return Hourglass;
      case "bankCheck":
        return BankCheck;
      case "exclamation":
        return Warning;
    }
  };

  const propsForType = (type: "checkmark" | "hourglass" | "presentHourglass" | "bankCheck" | "exclamation") => {
    switch (type) {
      case "checkmark":
        return { color: "primary" };
      case "hourglass":
        return { color: "secondary" };
      case "presentHourglass":
        return { color: "yellow" };
    }
  };

  return (
    <Stepper
      orientation="vertical"
      connector={
        <StepConnector
          className={classnames({ [classes.noconnector]: !(showConnector || showConnector === undefined) })}
        />
      }
    >
      {list.map(({ title, date, message, iconType, dispersementChart, moreDetailsText }, index) => (
        <Step active={true} key={title}>
          <StepLabel
            StepIconComponent={iconForType(iconType)}
            StepIconProps={propsForType(iconType)}
            id={`step-label-${!(showConnector || showConnector === undefined) ? "anticipated" : "completed"}-${index}`}
            data-testid={`step-label-${
              !(showConnector || showConnector === undefined) ? "anticipated" : "completed"
            }-${index}-title`}
          >
            <Typography className={classes.title} variant="h2">
              {title} {date && <Typography variant="caption">{date}</Typography>}
            </Typography>
          </StepLabel>
          <StepContent
            data-testid={`step-label-${
              !(showConnector || showConnector === undefined) ? "anticipated" : "completed"
            }-${index}-message`}
            aria-labelledby={`step-label-${
              !(showConnector || showConnector === undefined) ? "anticipated" : "completed"
            }-${index}`}
            className={classnames(classes.border, {
              [classes.noconnector]: !(showConnector || showConnector === undefined),
            })}
          >
            <Typography variant="body2">{message}</Typography>
            {(moreDetailsText || dispersementChart) && (
              <Link
                className={classes.moreDetails}
                component="button"
                variant="body2"
                color="secondary"
                data-testid={`moredetails-link`}
                aria-expanded={activeStep.includes(index)}
                aria-controls={`more-details-${index}`}
                onClick={() =>
                  activeStep.includes(index)
                    ? setActiveStep(activeStep.filter((step) => step !== index))
                    : setActiveStep(activeStep.concat(index))
                }
                endIcon={<Arrow expand={activeStep.includes(index)} />}
              >
                {activeStep.includes(index) ? t("hideDetails") : t("moreDetails")}
              </Link>
            )}
            {activeStep.includes(index) && (
              <div id={`more-details-${index}`}>
                {moreDetailsText && <Typography variant="body2">{moreDetailsText}</Typography>}
                {dispersementChart}
              </div>
            )}
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepList;
