import Header from "components/header";
import Wrapper from "components/wrapper";
import useStimulusAddressChange, { StatusCodeError } from "data/stimulus/useStimulusAddressChange";
import { Formik } from "formik";
import React, { useCallback, useState } from "react";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { routes } from "ui/routes";
import AccountLookupForm, {
  initialValues as accountLookupForm,
  useValidation as useAccountLookupValidation,
} from "./components/accountLookupForm";
import AddressAlreadyChanged from "./components/addressAlreadyChanged";
import AddressChangeFailure from "./components/addressChangeFailure";
import AddressChangeForm, {
  initialValues as addressChangeForm,
  useValidation as useAddressChangeValidation,
} from "./components/addressChangeForm";
import AddressChangePeriodEnded from "./components/addressChangePeriodEnded";
import AddressChangeSuccess from "./components/addressChangeSuccess";

export const ChangeAddress: React.FC = () => {
  const { token } = useParams<{ token?: string }>();
  const history = useHistory();
  const changeAddress = useStimulusAddressChange();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const schema = useAddressChangeValidation();
  const [formError, setFormError] = useState("");
  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      try {
        setFormError("");
        const reCaptchaToken = await executeRecaptcha();
        await changeAddress({ ...values, token: token?.toLowerCase(), reCaptchaToken });
        history.push(routes.stimulus.show.success.with({ token }));
      } catch (error) {
        if (error instanceof StatusCodeError) {
          switch (error.status) {
            case 400:
              setFormError(error.message);
              setSubmitting(false);
              break;
            case 403:
              history.push(routes.stimulus.show.error.with({ token }));
              break;
            case 409:
              history.push(routes.stimulus.show.alreadyChanged.with({ token }));
              break;
            default:
              throw error;
          }
        } else {
          throw error;
        }
      }
    },
    [executeRecaptcha, token, changeAddress, history],
  );
  return (
    <Formik initialValues={addressChangeForm} validateOnMount={true} validationSchema={schema} onSubmit={onSubmit}>
      {(formik) => <AddressChangeForm serverError={Boolean(formError)} {...formik}></AddressChangeForm>}
    </Formik>
  );
};

export const LookupAccount = () => {
  const history = useHistory();
  const schema = useAccountLookupValidation();

  const onSubmit = useCallback(
    async ({ token }) => {
      history.push(routes.stimulus.show.with({ token }));
    },
    [history],
  );
  return (
    <Formik initialValues={accountLookupForm} validateOnMount={true} validationSchema={schema} onSubmit={onSubmit}>
      {(formik) => <AccountLookupForm {...formik}></AccountLookupForm>}
    </Formik>
  );
};

export default () => {
  const [t] = useTranslation();
  const addressChangePeriodEnded = true;

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <Wrapper in={true} loading={false} timeout={750}>
        <>
          <Header title={t("welcome")}></Header>
          {addressChangePeriodEnded ? (
            <AddressChangePeriodEnded />
          ) : (
            <Switch>
              <Route exact path={routes.stimulus.path} component={LookupAccount} />
              <Route path={routes.stimulus.show.success.path} component={AddressChangeSuccess} />
              <Route path={routes.stimulus.show.alreadyChanged.path} component={AddressAlreadyChanged} />
              <Route path={routes.stimulus.show.error.path} component={AddressChangeFailure} />
              <Route exact path={routes.stimulus.show.path} component={ChangeAddress} />
            </Switch>
          )}
        </>
      </Wrapper>
    </GoogleReCaptchaProvider>
  );
};
