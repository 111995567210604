import AppBar from "@material-ui/core/AppBar";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import Logo from "./logo";

type Props = {
  title: string;
};

export const useStyles = makeStyles((theme: Theme) => ({
  bar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    display: "flex",
    flex: 1,
  },
  logo: {
    height: "3rem",
    width: "3rem",
    fill: theme.palette.primary.main,
  },
}));

const Header: React.FC<Props> = ({ title }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <AppBar position="relative">
      <Toolbar className={classes.bar}>
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
        <Logo className={classes.logo} title={t("aria.logo")}></Logo>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
