import React from "react";
import { Trans } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { medium } from "styles/fonts";
import classnames from "classnames";

export const useStyles = makeStyles((theme: Theme) => ({
  footerBackground: {
    backgroundColor: theme.palette.grey[100],
  },
  legal: {
    padding: "1rem",
  },
  privacy: {
    color: theme.palette.grey[700],
    fontSize: "0.75rem",
    fontWeight: medium,
    lineHeight: 1.625,
  },
  copyright: {
    color: theme.palette.grey[700],
    fontSize: "0.6875rem",
    fontWeight: medium,
    lineHeight: 1.625,
  },
  link: {
    cursor: "pointer",
    textTransform: "uppercase",
    "&:hover": {
      color: theme.palette.common.black,
    },
  },
}));

const DefaultFooter: React.FC = (props) => {
  const classes = useStyles();
  return (
    <Grid className={classnames(classes.legal, classes.footerBackground)} {...props}>
      <Typography className={classes.copyright} variant="body2">
        Copyright &copy; {new Date().getFullYear().toString()} HRB Digital LLC. All Rights Reserved.
      </Typography>
      <Typography className={classes.privacy} variant="body2">
        <Trans i18nKey={"privacyPolicy"}>
          See
          <Link
            className={classes.link}
            data-testid={"terms-of-use"}
            underline="always"
            href="https://www.hrblock.com/universal/terms.html"
            color="inherit"
            target="_blank"
            rel="noopener"
          >
            Terms of Use
          </Link>
          and
          <Link
            className={classes.link}
            data-testid={"privacy-policy"}
            underline="always"
            href="https://www.hrblock.com/universal/digital-online-mobile-privacy-principles.html"
            color="inherit"
            target="_blank"
            rel="noopener"
          >
            Privacy Policy
          </Link>
          at
          <Link
            className={classes.link}
            data-testid={"hrblock"}
            underline="always"
            href="https://www.hrblock.com"
            color="inherit"
            target="_blank"
            rel="noopener"
          >
            hrblock.com
          </Link>
          hrblock.com
        </Trans>
      </Typography>
    </Grid>
  );
};

export default DefaultFooter;
