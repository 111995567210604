import { StateCode } from "data/refund";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export type HRBlock = "HRBlock";
export type Federal = "federal";

export const useContact = () => {
  const [t] = useTranslation("contactInfo");
  return useCallback(
    (contactCode: HRBlock | Federal | StateCode = "HRBlock") => {
      return {
        name: t(`contacts.${contactCode}.name`),
        phone: t(`contacts.${contactCode}.phone`),
        web: t(`contacts.${contactCode}.web`),
        abv: t(`contacts.${contactCode}.abv`),
      };
    },
    [t],
  );
};
