import React from "react";
import BankCheckIcon from "icons/BankCheck";
import { useTranslation } from "react-i18next";
import IconBubble, { IconBubbleProps } from "./iconBubble";

type Props = Partial<IconBubbleProps>;

const BankCheck: React.FC<Props> = ({ color = "error" }) => {
  const [t] = useTranslation();

  return (
    <IconBubble color={color}>
      <BankCheckIcon titleAccess={t("aria.bankCheckIcon")}></BankCheckIcon>
    </IconBubble>
  );
};

export default BankCheck;
