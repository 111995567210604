import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ChevronDown from "icons/ChevronDown";

type Props = SvgIconProps & {
  expand: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  expand: {
    transform: "scaleY(-1)",
  },
}));

const Arrow: React.FC<Props> = ({ expand, className, ...props }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <ChevronDown
      className={classnames({ [classes.expand]: expand }, className)}
      {...props}
      titleAccess={expand ? t("aria.expandIcon") : t("aria.collapseIcon")}
    />
  );
};

export default Arrow;
