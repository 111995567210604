import React, { createContext, useContext, useEffect } from "react";
import { RefundState, RefundDispatch } from "./types";
import useRefundByToken, { RefundByToken } from "./useRefundByToken";

const StateContext = createContext<RefundState | undefined>(undefined);
const DispatchContext = createContext<RefundDispatch | undefined>(undefined);

export type RefundProviderProps = {
  token: string;
  useRefundByTokenFn?: () => RefundByToken;
};

export const RefundProvider: React.FC<RefundProviderProps> = ({ token, useRefundByTokenFn, children }) => {
  // context is really only used in testing
  // eslint rules of hooks doesn't know this which is why the following line is disabled
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, getRefund] = useRefundByTokenFn ? useRefundByTokenFn() : useRefundByToken();

  useEffect(() => {
    getRefund(token);
  }, [getRefund, token]);

  if (state.error) {
    throw state.error;
  }

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider
        value={{
          getRefund,
        }}
      >
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};
function useRefundState() {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useRefundState must be used within a RefundProvider");
  }
  return context;
}

function useRefundDispatch() {
  const context = useContext(DispatchContext);
  if (context === undefined) {
    throw new Error("useRefundDispatch must be used within a RefundProvider");
  }
  return context;
}

export function useRefund(): [RefundState, RefundDispatch] {
  return [useRefundState(), useRefundDispatch()];
}
