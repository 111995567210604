import React from "react";
import classnames from "classnames";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

type Props = {
  label: string;
  message?: string;
  icon?: React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    label: {
      color: theme.palette.common.white,
      fontSize: "0.75rem",
      fontWeight: 700,
      lineHeight: "1rem",
    },
    message: {
      color: theme.palette.primary.main,
      fontSize: "0.875rem",
      fontWeight: 700,
      lineHeight: "1rem",
      display: "flex",
      "& > svg": {
        height: "1rem",
        marginLeft: "0.25rem",
      },
    },
    flag: {
      padding: "0.25rem 2rem 0.25rem 0.5rem",
      display: "inline-block",
      position: "relative",
      overflow: "hidden",
      lineHeight: "1rem",
      "&:before": {
        content: "''",
        transformOrigin: "top right",
        transform: "skewX(-15deg)",
        position: "absolute",
        background: theme.palette.grey[900],
        left: "-45px",
        height: "100%",
        right: "0",
        top: "0",
      },
      "&:after": {
        content: "''",
        position: "absolute",
        left: "-45px",
        height: "100%",
        right: "0",
        top: "0",
        transformOrigin: "bottom right",
        transform: "skewX(15deg)",
        background: theme.palette.grey[900],
      },
      "& > p": {
        position: "relative",
        zIndex: 10,
      },
    },
    noMessage: {
      paddingTop: "0.6875rem",
      paddingBottom: "0.6875rem",
      "&:before": {
        background: theme.palette.grey[600],
      },
      "&:after": {
        background: theme.palette.grey[600],
      },
    },
  };
});

const DisbursmentFlag: React.FC<Props> = ({ label, message, icon }) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.flag, { [classes.noMessage]: !(message || icon) })}>
      <Typography className={classes.label}>{label}</Typography>
      {(message || icon) && (
        <Typography className={classes.message}>
          {message}
          {icon}
        </Typography>
      )}
    </div>
  );
};

export default DisbursmentFlag;
