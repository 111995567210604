import React from "react";
import { useTranslation } from "react-i18next";
import { DisbursementType } from "data/refund";
import DisbursementFlag from "components/disbursementFlag";
import EmeraldCard from "icons/EmeraldCard";
import DirectDeposit from "icons/DirectDeposit";
import BankCheck from "icons/BankCheck";

type Props = {
  type: DisbursementType;
};

const DisbursementFlagType: React.FC<Props> = ({ type }) => {
  const [t] = useTranslation();
  switch (type) {
    case "emerald-card":
      return (
        <DisbursementFlag
          label={t("sentTo")}
          message={t("emeraldCard")}
          icon={<EmeraldCard titleAccess={t("aria.emeraldCardIcon")} />}
        />
      );
    case "direct-deposit":
      return (
        <DisbursementFlag
          label={t("sentTo")}
          message={t("directDeposit")}
          icon={<DirectDeposit titleAccess={t("aria.directDepositIcon")} />}
        />
      );
    case "check":
      return (
        <DisbursementFlag
          label={t("sentTo")}
          message={t("check")}
          icon={<BankCheck titleAccess={t("aria.checkIcon")} />}
        />
      );
    default:
      return <DisbursementFlag label={t("noDisbursement")} />;
  }
};

export default DisbursementFlagType;
