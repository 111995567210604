import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const BankCheck: React.FC<SvgIconProps> = ({ titleAccess, ...props }) => {
  return (
    <SvgIcon {...props} aria-label={titleAccess} titleAccess={titleAccess}>
      <path d="M21.4776 2.30602C21.8856 1.89799 22.5472 1.89799 22.9552 2.30602L23.694 3.04482C24.102 3.45284 24.102 4.11438 23.694 4.52241L12.7388 15.4776C12.3308 15.8856 11.6692 15.8856 11.2612 15.4776L10.5224 14.7388C10.1144 14.3308 10.1144 13.6692 10.5224 13.2612L21.4776 2.30602Z" />
      <path d="M10.153 15.1082C10.357 14.9042 10.6878 14.9042 10.8918 15.1082C11.0958 15.3122 11.0958 15.643 10.8918 15.847C10.6878 16.051 10.357 16.051 10.153 15.847C9.949 15.643 9.949 15.3122 10.153 15.1082Z" />
      <path d="M16 5L14 7H2V20H17V15H22V9L24 7V18L20 22H0V5H16ZM19 17H21.5858L19 19.5858V17Z" />
      <path d="M4 17H15V19H4V17Z" />
      <path d="M6.5 9.5V9H5.5V9.5C4.67157 9.5 4 10.1716 4 11C4 11.8284 4.67157 12.5 5.5 12.5H6.5C6.77614 12.5 7 12.7239 7 13C7 13.2761 6.77614 13.5 6.5 13.5H5.5C5.22386 13.5 5 13.2761 5 13H4C4 13.8284 4.67157 14.5 5.5 14.5V15H6.5V14.5C7.32843 14.5 8 13.8284 8 13C8 12.1716 7.32843 11.5 6.5 11.5H5.5C5.22386 11.5 5 11.2761 5 11C5 10.7239 5.22386 10.5 5.5 10.5H6.5C6.77614 10.5 7 10.7239 7 11H8C8 10.1716 7.32843 9.5 6.5 9.5Z" />
    </SvgIcon>
  );
};

export default BankCheck;
