import React, { useMemo } from "react";
import { useRefund, RefundMessage } from "data/refund";
import StepList, { StepItem } from "components/steplist";
import { useCompletedRefundStep, useEFiledStep, useEFileAcceptedStep } from "./useRefundStep";

const CompletedSteps: React.FC = () => {
  const [{ refund }] = useRefund();
  const getEfileStep = useEFiledStep();
  const getEfileAcceptedStep = useEFileAcceptedStep();
  const getStep = useCompletedRefundStep();
  const defaultSteps = useMemo(() => [getEfileStep(refund!), getEfileAcceptedStep(refund!)] as StepItem[], [
    getEfileStep,
    getEfileAcceptedStep,
    refund,
  ]);
  const refundSteps = useMemo(() => {
    const steps = [getStep(refund!, "state"), getStep(refund!, "federal")].filter(
      (step) => step !== null,
    ) as StepItem[];
    const stateReceivedAfterFed = new Set([
      RefundMessage.StateReceivedAfterFed,
      RefundMessage.StateReceivedAfterFedLowerAmount,
    ]);
    return refund!.state && stateReceivedAfterFed.has(refund!.state.messageNumber) ? steps.reverse() : steps;
  }, [getStep, refund]);
  const completedSteps = useMemo(() => [...defaultSteps, ...refundSteps], [defaultSteps, refundSteps]);

  return <StepList list={completedSteps} />;
};

export default CompletedSteps;
