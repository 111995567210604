import { route } from "static-route-paths";

export const routes = route({
  refund: route(":token"),
  stimulus: route("stimulus", {
    show: route(":token", {
      success: route("success"),
      alreadyChanged: route("alreadyChanged"),
      error: route("error"),
    }),
  }),
  errors: route({
    notFound: route("not-found"),
  }),
});
