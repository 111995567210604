import DocumentTitle from "components/documentTitle";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import NotFound from "./errors/notFound";
import UnhandledError from "./errors/unhandledError";
import RefundIndexRoute from "./refund";
import { routes } from "./routes";
import StimulusIndexRoute from "./stimulus";

const App: React.FC = () => {
  const [t] = useTranslation();

  return (
    <DocumentTitle title={t("app.title")}>
      <ErrorBoundary FallbackComponent={UnhandledError}>
        <Router>
          <Switch>
            <Route path={routes.errors.notFound.path} component={NotFound} />
            <Route path={routes.stimulus.path} component={StimulusIndexRoute} />
            <Route exact path={routes.refund.path} component={RefundIndexRoute} />
            <Redirect to={routes.errors.notFound.path} />
          </Switch>
        </Router>
      </ErrorBoundary>
    </DocumentTitle>
  );
};

export default App;
