import React from "react";
import classnames from "classnames";
import MuiLink from "@material-ui/core/Link";
import { makeStyles, Theme, fade } from "@material-ui/core/styles";
import { bold } from "styles/fonts";

const underline = (color: string) => {
  return {
    cursor: "pointer",
    fontWeight: bold,
    transition: "all .2s ease-in-out",
    "box-shadow": `inset 0 -0.375rem 0 ${fade(color, 0.4)}`,
    "&:hover": {
      "text-decoration": "none",
      "box-shadow": `inset 0 -4.0625rem 0 ${color}`,
    },
  };
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&.MuiLink-underlineHover": {
      "&:hover": {
        "text-decoration": "none",
      },
      "&.MuiTypography-colorPrimary": {
        color: theme.palette.common.black,
      },
      "&.MuiTypography-colorSecondary": {
        color: theme.palette.common.black,
      },
      "&.MuiTypography-colorError": {
        color: theme.palette.common.black,
      },
      "&.MuiTypography-colorPrimary span": {
        ...underline(theme.palette.primary.main),
      },
      "&.MuiTypography-colorSecondary span": {
        ...underline(theme.palette.secondary.main),
      },
      "&.MuiTypography-colorError span": {
        ...underline(theme.palette.error.main),
      },
    },
  },
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LinkProps = any; // TODO: I can't seem to make Typescript happy, this should be whatever props MuiLink takes

type Props = LinkProps & {
  endIcon?: React.ReactNode;
};

const Link: React.FC<Props> = ({ className, children, endIcon, ...props }) => {
  const classes = useStyles();
  return (
    <MuiLink className={classnames(className, classes.root)} {...props}>
      {children && <span>{children}</span>}
      {endIcon}
    </MuiLink>
  );
};

export default Link;
