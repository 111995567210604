import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { SelectInput } from "components/selectInput";
import { TextInput } from "components/textInput";
import { useStates } from "data/contact/useStates";
import { FormikProps } from "formik";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const useValidation = () => {
  const [t] = useTranslation();
  return useMemo(() => {
    return Yup.object().shape({
      ssn: Yup.string()
        .required(t("identityVerification.ssn.required"))
        .min(4, (params) => t("identityVerification.ssn.minLength", params))
        .max(4, (params) => t("identityVerification.ssn.maxLength", params))
        .matches(/^([0-9]{4})$/, {
          excludeEmptyString: true,
        }),
      addressLine1: Yup.string()
        .required(t("address.addressLine1.required"))
        .min(1, (params) => t("address.addressLine1.minLength", params))
        .max(40, (params) => t("address.addressLine1.maxLength", params))
        .matches(/[\s\S]*/),
      addressLine2: Yup.string()
        .min(0, (params) => t("address.addressLine2.minLength", params))
        .max(40, (params) => t("address.addressLine2.maxLength", params))
        .matches(/[\s\S]*/),
      city: Yup.string()
        .required(t("address.city.required"))
        .min(1, (params) => t("address.city.minLength", params))
        .max(22, (params) => t("address.city.maxLength", params))
        .matches(/[\s\S]*/),
      state: Yup.string()
        .required(t("address.state.required"))
        .min(2, (params) => t("address.state.minLength", params))
        .max(2, (params) => t("address.state.maxLength", params))
        .matches(/^[A-Z][A-Z]$/, { excludeEmptyString: true }),
      zipcode: Yup.string()
        .required(t("address.zipcode.required"))
        .min(5, (params) => t("address.zipcode.minLength", params))
        .max(5, (params) => t("address.zipcode.maxLength", params))
        .matches(/^([0-9]{5})$/, {
          excludeEmptyString: true,
        }),
    });
  }, [t]);
};

enum FormFields {
  SSN = "ssn",
  AddressLine1 = "addressLine1",
  AddressLine2 = "addressLine2",
  City = "city",
  State = "state",
  Zipcode = "zipcode",
}

export type Form = {
  ssn: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipcode: string;
};

export const initialValues: Form = { ssn: "", addressLine1: "", addressLine2: "", city: "", state: "", zipcode: "" };

type Props = FormikProps<Form> & {
  serverError?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    width: "100%",
    margin: 0,
  },
  submit: {
    width: "100%",
    height: "3.75rem",
  },
}));

const AddressChangeForm: React.FC<Props> = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const states = useStates();
  const {
    values: { ssn, addressLine1, addressLine2, city, state, zipcode },
    errors,
    touched,
    isValid,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldTouched,
    serverError,
  } = props;

  const change = (name: FormFields, e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} className={classes.formContainer}>
        <Grid item xs={12}>
          <Typography variant="body1">{t("identityVerification.instructions")}</Typography>
        </Grid>
        {serverError && (
          <Grid item xs={12}>
            <Typography color="error" variant="body1">
              {t("address.serverError")}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextInput
            id={FormFields.SSN}
            name={FormFields.SSN}
            type="password"
            label={t("identityVerification.ssn.label")}
            helperText={touched.ssn ? errors.ssn : ""}
            error={touched.ssn && Boolean(errors.ssn)}
            value={ssn}
            onChange={change.bind(null, FormFields.SSN)}
            required
            inputProps={{ minLength: 4, maxLength: 4, inputmode: "numeric" }}
          ></TextInput>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.formContainer}>
        <Grid item xs={12}>
          <Typography variant="body1">{t("address.instructions")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id={FormFields.AddressLine1}
            name={FormFields.AddressLine1}
            label={t("address.addressLine1.label")}
            helperText={touched.addressLine1 ? errors.addressLine1 : ""}
            error={touched.addressLine1 && Boolean(errors.addressLine1)}
            value={addressLine1}
            onChange={change.bind(null, FormFields.AddressLine1)}
            required
            inputProps={{ minLength: 1, maxLength: 40 }}
          ></TextInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id={FormFields.AddressLine2}
            name={FormFields.AddressLine2}
            label={t("address.addressLine2.label")}
            helperText={touched.addressLine2 ? errors.addressLine2 : ""}
            error={touched.addressLine2 && Boolean(errors.addressLine2)}
            value={addressLine2}
            onChange={change.bind(null, FormFields.AddressLine2)}
            inputProps={{ maxLength: 40 }}
          ></TextInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id={FormFields.City}
            name={FormFields.City}
            label={t("address.city.label")}
            helperText={touched.city ? errors.city : ""}
            error={touched.city && Boolean(errors.city)}
            value={city}
            onChange={change.bind(null, FormFields.City)}
            required
            inputProps={{ minLength: 1, maxLength: 22 }}
          ></TextInput>
        </Grid>
        <Grid item xs={6} sm={6}>
          <SelectInput
            id={FormFields.State}
            name={FormFields.State}
            label={t("address.state.label")}
            value={state}
            options={states.map(({ name, abv }) => ({ label: name, value: abv }))}
            helperText={touched.state ? errors.state : ""}
            error={touched.state && Boolean(errors.state)}
            required
            onChange={change.bind(null, FormFields.State)}
          ></SelectInput>
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextInput
            id={FormFields.Zipcode}
            name={FormFields.Zipcode}
            label={t("address.zipcode.label")}
            helperText={touched.zipcode ? errors.zipcode : ""}
            error={touched.zipcode && Boolean(errors.zipcode)}
            value={zipcode}
            onChange={change.bind(null, FormFields.Zipcode)}
            required
            inputProps={{ minLength: 5, maxLength: 5, inputmode: "numeric" }}
          ></TextInput>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">{t("address.confirmText")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">{t("address.disclaimer")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.submit}
            disabled={!isValid || isSubmitting}
          >
            {isSubmitting ? t("submitting") : t("submit")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddressChangeForm;
