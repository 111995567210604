import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => {
  return {
    message: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    contact: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      display: "flex",
      flexDirection: "row",

      "& .MuiTypography-body1": {
        marginLeft: "1rem",
      },
    },
    phoneNumber: {
      fontWeight: "bold",
      whiteSpace: "nowrap",
    },
  };
});

export { useStyles };
