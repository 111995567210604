import React from "react";
import { useTranslation } from "react-i18next";
import CheckIcon from "icons/Checkmark";
import IconBubble, { IconBubbleProps } from "./iconBubble";

type Props = Partial<IconBubbleProps>;

const Checkmark: React.FC<Props> = ({ color = "primary" }) => {
  const [t] = useTranslation();

  return (
    <IconBubble color={color}>
      <CheckIcon titleAccess={t("aria.checkmarkIcon")}></CheckIcon>
    </IconBubble>
  );
};

export default Checkmark;
