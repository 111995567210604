import React from "react";
import Logo from "./logo";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export const useStyles = makeStyles((theme: Theme) => ({
  loadingText: {
    fontWeight: "bold",
    fontStyle: "normal",
    margin: "1rem",
    color: theme.palette.common.white,
  },
  logo: {
    height: "3rem",
    width: "3rem",
    fill: theme.palette.primary.main,
    margin: "1rem",
  },
}));

type Props = {
  style?: React.CSSProperties;
};

const LoadingScreen: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [t] = useTranslation();
  return (
    <div className={"loading-backdrop"} {...props}>
      <Typography variant="h5" className={classes.loadingText}>
        {t("loading")}
      </Typography>
      <Logo className={classes.logo} title={t("aria.logo")}></Logo>
    </div>
  );
};

export default LoadingScreen;
