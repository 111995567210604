import { useCallback } from "react";
import { StimulusAddressChangeRequest, StimulusChangeAddress } from "./types";

export class StatusCodeError extends Error {
  constructor(public status: number, message: string) {
    super(message);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, StatusCodeError);
    }

    this.name = "StatusCodeError";
    this.status = status;
  }
}

export default function useStimulusAddressChange(
  baseUrl = process.env.REACT_APP_API_BASE_URL || "/",
): StimulusChangeAddress {
  return useCallback(
    async (params: StimulusAddressChangeRequest) => {
      const response = await fetch(`${baseUrl}default/update`, {
        method: "POST",
        cache: "no-store",
        body: JSON.stringify(params),
      });
      switch (response.status) {
        case 200: {
          break;
        }
        default: {
          const json = await response.json();
          throw new StatusCodeError(response.status, json?.error?.message || "Unknown Error");
        }
      }
    },
    [baseUrl],
  );
}
