import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Ledger, { LedgerLineItem } from "components/ledger";
import { Refund, RefundMessage } from "data/refund";
import { useFees } from "data/fees";
import DisbursementFlagType from "./disbursementFlagType";
import DisbursementMessage from "./disbursementMessage";

type Props = {
  refundInfo: Refund;
};

const DisbursementTable: React.FC<Props> = ({
  refundInfo: { messageNumber, stateCode, amountReceived, payments, disbursementType },
}) => {
  const [t] = useTranslation();
  const fee = useFees();

  const credits = useMemo(() => {
    if (stateCode) {
      return { name: t("stateTotalRecieved"), amount: amountReceived || 0 };
    } else {
      return { name: t("fedTotalRecieved"), amount: amountReceived || 0 };
    }
  }, [t, stateCode, amountReceived]);
  const debits = useMemo(() => {
    const allDebits = payments.map(({ fid, amount }) => ({
      name: fee(fid),
      amount,
    }));
    return Object.values(
      allDebits.reduce((acc, { name, amount }) => {
        const prev = acc[name];
        return {
          ...acc,
          [name]: {
            name,
            amount: prev ? prev.amount + amount : amount,
          },
        };
      }, {} as { [key: string]: LedgerLineItem }),
    );
  }, [fee, payments]);
  const isFlipToCheck = useMemo(
    () => [RefundMessage.FederalFlipToCheck, RefundMessage.StateFlipToCheck].includes(messageNumber),
    [messageNumber],
  );

  return (
    <Ledger
      credits={credits}
      debits={debits}
      disbursementFlag={<DisbursementFlagType type={disbursementType!} />}
      disbursementDetail={!isFlipToCheck ? <DisbursementMessage type={disbursementType!} /> : null}
    />
  );
};
export default DisbursementTable;
